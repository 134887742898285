import { SVGProps } from "react";

// video modes svg
export function AnimationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
        <path
          className="fill-white stroke-0"
          d="m43.92,43.4c-.5-.33-1.01-.66-1.52-.99-.48-.31-.97-.63-1.47-.96,1.2-1.65,2.38-3.28,3.57-4.92-.39-.34-.77-.68-1.16-1.02-.15-.13-.31-.28-.49-.36-1.43-.6-2.88-1.2-4.32-1.79-.28-.12-.57-.1-.84.06-1.4.85-2.8,1.69-4.19,2.55-.44.27-.55.84-.28,1.28.28.47.85.6,1.36.3,1.17-.7,2.33-1.41,3.5-2.11.1-.06.26-.08.37-.03.77.3,1.53.62,2.29.94.08.03.16.07.25.12-.06.09-.1.15-.14.22-.91,1.31-1.82,2.63-2.74,3.94-.38.56-.28,1.1.28,1.47.31.21.63.41.94.61.97.63,1.94,1.26,2.92,1.9-.02.07-.03.11-.05.15-.69,1.77-1.38,3.53-2.06,5.3-.07.18-.12.4-.1.59.04.45.4.78.84.83.44.05.84-.2,1.02-.65.81-2.07,1.61-4.14,2.42-6.21.2-.51.06-.93-.4-1.24Z"
        />
        <path
          className="fill-white stroke-0"
          d="m44.86,34.88c1.74.01,3.18-1.42,3.19-3.18.01-1.74-1.41-3.18-3.15-3.19-1.77-.01-3.21,1.4-3.23,3.17-.02,1.76,1.41,3.2,3.19,3.21Z"
        />
        <path
          className="fill-white stroke-0"
          d="m38.76,42.66c-.54-.42-1.35-.63-1.12-1.59-.02,0-.04,0-.07,0-.06.07-.12.14-.18.21-.75.94-1.49,1.88-2.24,2.81-.06.08-.19.16-.28.15-1.09-.08-2.19-.16-3.28-.26-.59-.05-1.07.32-1.12.86-.05.56.34,1.01.94,1.06,1.3.11,2.59.2,3.89.31.4.03.71-.1.96-.42.72-.92,1.45-1.82,2.18-2.74.1-.13.21-.26.33-.41Z"
        />
        <path
          className="fill-white stroke-0"
          d="m51.09,37.28c-.22-.51-.78-.72-1.33-.49-.99.43-1.99.84-2.97,1.29-.31.14-.51.13-.75-.12-.39-.39-.82-.73-1.25-1.11-.39.52-.76,1.02-1.14,1.55.63.55,1.24,1.08,1.84,1.62.37.33.76.38,1.2.18,1.28-.55,2.56-1.1,3.84-1.65.53-.23.77-.78.56-1.28Z"
        />
        <rect className="fill-white stroke-0" x="25.19" y="24.11" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="31.69" y="24.11" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="38.18" y="24.11" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="44.67" y="24.11" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="51.17" y="24.11" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="25.19" y="53.76" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="31.69" y="53.76" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="38.18" y="53.76" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="44.67" y="53.76" width="4.25" height="2.1" />
        <rect className="fill-white stroke-0" x="51.17" y="53.76" width="4.25" height="2.1" />
        <path
          className="fill-white stroke-0"
          d="m19.27,60.73h41.46V19.27H19.27v41.46Zm2.63-38.83h36.2v36.2H21.9V21.9Z"
        />
      </svg>
    </>
  );
}

export function FaceSwapVideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m42.38,35.19c1.92-.16,3.5-1.75,3.73-3.68.04-.35.08-.69.13-1.09.18.01.34,0,.48.04.31.11.63.23.92.4.73.44,1.32,1.05,1.9,1.67.64.67,1.01,1.49,1.1,2.41.07.7.07,1.41.08,2.11.01.92,0,1.83,0,2.75.01.79.5,1.28,1.29,1.31.11,0,.21,0,.32,0,.77-.04,1.27.36,1.57,1.02.34.76.3,1.51-.13,2.23-.37.62-.94.8-1.63.75-.75-.05-1.46.46-1.44,1.45.01.72-.03,1.45-.15,2.16-.26,1.6-1.03,2.94-2.17,4.09-.79.79-1.68,1.45-2.62,2.05-.32.21-.44.44-.44.82.02,1.16,0,2.33,0,3.49,0,.17.03.34.04.5.53.12,9.85.11,10.32-.01,0-.16.03-.33.03-.5,0-7.57,0-15.13,0-22.7,0-1.35-.19-2.67-.53-3.97-.99-3.74-3.17-6.62-6.39-8.7-2.71-1.74-5.65-2.54-8.79-2.34v13.81s.06,0,.1,0c.76,0,1.52-.01,2.27-.08Z"
      />
      <path
        className="fill-white stroke-0"
        d="m23.94,31.63c.27,1.5.35,3,.15,4.52-.18,1.37-.16,2.74.37,4.06.08.2.04.47-.01.69-.55,2.5,0,4.82,1.19,7.02.1.18.23.38.4.49.72.45.91,1.18,1.17,1.92,1,2.84,2.49,5.37,4.89,7.26,1.65,1.31,3.48,1.95,5.31,2.01v4.74h1.06V15.64h-1.06v5.84c-.06-.07-.11-.11-.18-.2-.51-.67-.99-1.37-1.5-2.05-.23-.3-.45-.66-1.06-.46-.12.71-.26,1.48-.42,2.43-.46-.33-.77-.53-1.06-.77-.68-.56-1.33-1.16-2.03-1.69-.31-.23-.71-.35-1.25-.61,0,.56-.04.9,0,1.23.08.52.24,1.03.33,1.55.07.38.13.77.1,1.15-.03.45-.41.6-.75.3-.24-.21-.41-.48-.62-.72-.66-.78-.99-.75-1.45.15-.03.06-.07.13-.09.19-.69,1.79-1.63,3.43-2.85,4.91-.8.97-1.09,2.12-.88,3.37.08.45.14.91.22,1.36Zm4.3,11.83c-.02-.31.01-.57-.07-.8-.45-1.19-.47-2.42-.36-3.65.19-2.01,1.31-3.28,3.22-3.69.37-.08.79-.12,1.14-.02,1.74.48,3.49.54,5.24.49v22.52c-1.51-.05-3-.56-4.37-1.62-2.44-1.88-3.86-4.44-4.8-7.31-.16-.5-.31-1.01-.47-1.56-.72,0-1.06-.51-1.31-1.06-.69-1.53-.99-3.14-.93-4.9,1.05.29,1.44,1.53,2.71,1.61Z"
      />
      <path
        className="fill-white stroke-0"
        d="m24.04,59.31c1.57,1.5,3.57,2.46,5.65,2.83l.07-.34c-1.97-.51-3.8-1.59-5.12-3.07-1.11-1.25-1.84-2.77-2.17-4.38l1.05.39-1.91-4-1.53,4.16,1.11-.53c.49,1.89,1.47,3.63,2.85,4.95Z"
      />
      <path
        className="fill-white stroke-0"
        d="m57.52,25.24l-1.05-.39,1.91,4,1.53-4.16-1.11.53c-.49-1.89-1.47-3.63-2.85-4.95-1.57-1.5-3.57-2.46-5.65-2.83l-.07.34c1.97.51,3.8,1.59,5.12,3.07,1.11,1.25,1.84,2.77,2.17,4.38Z"
      />
    </svg>
  );
}

export function ImageToVideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m22.68,47.13h8.5v-2.02h-8.31c-1.51,0-2.74-1.23-2.74-2.74v-1.47c0-.23.09-.45.25-.61l7.09-7.08,3.8,3.57c.42-3.53,3.4-6.29,7.04-6.29h8.15v-7.14c0-2.64-2.11-4.75-4.75-4.75h-19.03c-2.61,0-4.75,2.11-4.75,4.75v19.03c0,2.61,2.14,4.75,4.75,4.75Zm3.95-23.02c1.76,0,3.19,1.43,3.19,3.19s-1.43,3.19-3.19,3.19-3.19-1.43-3.19-3.19,1.43-3.19,3.19-3.19Z"
      />
      <path
        className="fill-white stroke-0"
        d="m57.32,32.87h-19.03c-2.62,0-4.75,2.13-4.75,4.75v19.03c0,2.62,2.13,4.75,4.75,4.75h19.03c2.62,0,4.75-2.13,4.75-4.75v-19.03c0-2.62-2.13-4.75-4.75-4.75Zm-4.18,15.8l-9.43,5.42c-.83.5-1.83-.09-1.83-1.02v-11.85c0-.97,1.07-1.54,1.9-.97l9.43,6.39c.76.52.67,1.64-.07,2.04Z"
      />
    </svg>
  );
}

export function LipSyncIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m28.64,49.68c.72.29,1.45.54,2.19.77,1.29.4,2.6.68,3.96.7.82.01,1.64-.02,2.45-.08,1.33-.1,2.64-.37,3.88-.89,1.89-.8,3.26-2.11,4-4.05.48-1.24.64-2.54.68-3.87-.06-.54-.07-1.09-.19-1.62-.18-.8-.79-1.13-1.58-.93-.17.04-.33.1-.49.17-.71.32-1.29.82-1.86,1.35-.29.27-.6.54-.93.77-.81.56-1.73.83-2.68,1-1.77.32-3.55.3-5.34.18-1.69-.12-3.36-.4-5-.8-1.92-.47-3.84-.98-5.76-1.47-.75-.19-1.5-.38-2.27-.45.91-.56,1.86-1.05,2.87-1.4,1.4-.48,2.85-.81,4.28-1.18,2.77-.72,5.58-1.34,8.43-1.64,1.33-.14,2.53-.16,3.84.15,1.22.28,2.39.78,3.38,1.54.97.74,2.33,1.23,3.36.28.35-.32.56-.77.66-1.23.22-1.02.06-2.18-.33-3.14-1.16-2.85-4.5-4.29-7.3-2.86-1.59.81-2.91-.14-4.52-.34-3.78-.47-7.15,1.98-10.19,3.85-1.94,1.19-3.86,2.46-5.72,3.73-.53.37-3.66,2.33-4.25,2.71.02-.01.05-.02.08-.02-.14.07-.24.17-.26.33-.03.21.08.36.3.48.83.45,1.66.88,2.47,1.37,1.54.93,3.05,1.89,4.59,2.83,2.33,1.42,4.71,2.74,7.25,3.76Z"
      />
      <path
        className="fill-white stroke-0"
        d="m62.08,43.58c0-2.19,0-11.65,0-13.84,0-.14.01-.27-.01-.41-.06-.33-.27-.51-.6-.5-.3.01-.5.19-.55.5-.02.12,0,.24,0,.37,0,4.4,0,16.08,0,20.48,0,.15-.01.3.02.45.07.29.29.49.56.42.21-.05.4-.26.56-.44.07-.08.03-.26.03-.4,0-2.22,0-4.43,0-6.65Z"
      />
      <path
        className="fill-white stroke-0"
        d="m58.36,32.27c0-.49-.22-.76-.59-.75-.36,0-.57.27-.57.74,0,1.36,0,9.99,0,11.34,0,1.36,0,2.71,0,4.07,0,.17.02.41.13.49.17.13.44.23.64.2.32-.06.4-.35.4-.67,0-2.71,0-12.7,0-15.42Z"
      />
      <path
        className="fill-white stroke-0"
        d="m54.48,48.95c0-1.42,0-2.84,0-4.26,0-1.44,0-12.28,0-13.72,0-.6-.22-.94-.6-.94-.37,0-.61.35-.61.94v17.98c0,.6.23.94.6.94.38,0,.6-.34.6-.94Z"
      />
      <path
        className="fill-white stroke-0"
        d="m50.02,46.44c.36,0,.59-.27.59-.72,0-.7,0-1.41,0-2.11,0-.7,0-8.68,0-9.39,0-.46-.22-.73-.58-.73-.36,0-.59.27-.59.72,0,1.41,0,10.09,0,11.5,0,.46.22.73.58.73Z"
      />
      <path
        className="fill-white stroke-0"
        d="m65.39,34.13c-.33,0-.58.26-.59.65-.01.39,0,8.06,0,8.45,0,.42-.01.84,0,1.26.01.38.28.64.61.63.32-.01.56-.26.56-.62,0-.81,0-8.9,0-9.71,0-.39-.25-.65-.58-.65Z"
      />
    </svg>
  );
}

export function TextToVideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m33.61,37.64v18.84c0,2.6,2.11,4.7,4.71,4.7h18.84c2.6,0,4.71-2.11,4.71-4.7v-18.84c0-2.6-2.11-4.71-4.71-4.71h-18.84c-2.6,0-4.71,2.11-4.71,4.71Zm8.26,3.55c0-.96,1.06-1.53,1.88-.96l9.34,6.33c.75.52.66,1.62-.07,2.02l-9.34,5.36c-.82.49-1.81-.09-1.81-1.01v-11.74Z"
      />
      <path
        className="fill-white stroke-0"
        d="m41.69,18.81h-18.84c-2.59,0-4.71,2.09-4.71,4.71v18.84c0,2.32,1.73,4.17,3.94,4.55v6.65l5.03-6.5h4.24v-1.25s0-7.83,0-7.83h-7.67c-.63,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13h7.9c.22-.79.59-1.53,1.05-2.18h-8.95c-.63,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13h11.67c.9-.43,1.9-.69,2.97-.69h8.08v-7.07c0-2.61-2.09-4.71-4.71-4.71Zm-.33,10.41h-17.68c-.63,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13h17.68c.63,0,1.13.51,1.13,1.13s-.51,1.13-1.13,1.13Z"
      />
    </svg>
  );
}

export function VideoToVideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m57.3,32.88h-19c-2.62,0-4.74,2.12-4.74,4.74v19c0,2.62,2.12,4.74,4.74,4.74h19c2.62,0,4.74-2.12,4.74-4.74v-19c0-2.62-2.12-4.74-4.74-4.74Zm-4.18,15.78l-9.42,5.41c-.83.5-1.83-.1-1.83-1.02v-11.84c0-.97,1.07-1.54,1.9-.97l9.42,6.38c.76.52.66,1.64-.07,2.04Z"
      />
      <path
        className="fill-white stroke-0"
        d="m27.35,38.79v-11.84c0-.97,1.07-1.54,1.9-.97l7.11,4.82c.62-.18,1.26-.3,1.93-.3h8.14v-7.13c0-2.63-2.11-4.74-4.74-4.74h-19c-2.61,0-4.74,2.11-4.74,4.74v19c0,2.61,2.14,4.74,4.74,4.74h8.49v-.03s.08.03.08.03v-8.51l-2.09,1.2c-.83.5-1.83-.09-1.83-1.02Z"
      />
    </svg>
  );
}

// image mode svg
export function AiHeadshotGeneratorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m32.53,31.57c-.59.24-.87.73-.8,1.38.11,1,.55,1.82,1.36,2.4.41.29.64.64.7,1.12.02.17.05.34.09.5.35,1.62.99,3.11,2.18,4.3,1.07,1.07,2.34,1.77,3.88,1.79,1.81.02,3.23-.83,4.38-2.17,1.1-1.27,1.65-2.79,1.92-4.43.07-.46.26-.81.65-1.08.77-.54,1.22-1.3,1.39-2.22.14-.77-.15-1.35-.78-1.59,0-.05.01-.1.02-.15.31-.69.4-1.48.32-2.26,0-.1,0-.13-.02-.09-.03-.23-.06-.47-.12-.69-.31-1.2-.93-2.34-1.84-3.19-.65-.6-1.33-.66-2.16-.5-.31.06-1.95.32-1.85.85-.51-2.74-4.77-1.92-6.61-1.59-1.16.21-2.38.46-3.21,1.37-1.39,1.5-.34,3.69.41,5.37.04.29.08.59.12.88Z"
      />
      <path
        className="fill-white stroke-0"
        d="m23.54,62.37h32.92c3.26,0,5.91-2.65,5.91-5.91V23.54c0-3.26-2.65-5.91-5.91-5.91H23.54c-3.26,0-5.91,2.65-5.91,5.91v32.92c0,3.26,2.65,5.91,5.91,5.91Zm-3.58-38.82c0-1.98,1.61-3.58,3.58-3.58h32.92c1.98,0,3.58,1.61,3.58,3.58v32.92c0,1.98-1.61,3.58-3.58,3.58h-.16c.14-.28.22-.58.23-.89.01-1.67-.04-3.35-.17-5.02-.11-1.37-.39-2.72-.59-4.08-.2-1.35-.87-2.39-2.09-2.99-.94-.46-1.91-.9-2.91-1.22-1.69-.54-3.41-.98-5.12-1.46-.02,0-.04.01-.08.03-1.23,2-2.47,4.01-3.72,6.06-.03-.08-.06-.11-.07-.15-.11-.89-.23-1.77-.32-2.66-.01-.1.08-.25.17-.32.37-.32.52-.89.34-1.41-.15-.44-.52-.71-1.03-.72-.6-.01-1.2-.01-1.79,0-.51,0-.88.28-1.03.71-.18.52-.06,1.11.34,1.4.15.11.19.22.17.4-.07.51-.13,1.01-.19,1.52-.05.4-.11.79-.17,1.28-1.29-2.1-2.53-4.12-3.76-6.11-.87.24-1.7.51-2.55.71-1.87.43-3.72.94-5.41,1.87-.49.27-.96.64-1.35,1.05-.6.62-.78,1.43-.93,2.27-.51,2.83-.84,5.67-.75,8.55,0,.17.01.34.05.51.05.25.14.47.25.68h-.24c-1.98,0-3.58-1.6-3.58-3.58V23.54Z"
      />
    </svg>
  );
}

export function AiImageGeneratorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m25.08,62.37h29.83c4.13,0,7.45-3.35,7.45-7.45v-29.83c0-4.13-3.31-7.45-7.45-7.45h-29.83c-4.1,0-7.45,3.31-7.45,7.45v29.83c0,4.1,3.35,7.45,7.45,7.45Zm1.74-34.38c1.55-1.46,2.37-3.01,2.78-3.96.41.95,1.23,2.5,2.78,3.96,1.78,1.68,3.66,2.41,4.69,2.72-1.03.32-2.9,1.04-4.69,2.72-1.55,1.46-2.37,3.01-2.78,3.96-.41-.95-1.23-2.5-2.78-3.96-1.78-1.68-3.66-2.41-4.69-2.72,1.03-.32,2.9-1.04,4.69-2.72Zm-5.75,24.6c0-.36.14-.7.39-.95l11.11-11.1,8.44,7.93,18.73-18.17v24.57c0,2.39-1.93,4.32-4.32,4.32h-30.06c-2.37,0-4.3-1.92-4.3-4.3v-2.31Z"
      />
    </svg>
  );
}

export function AiImageUpscalerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m41.06,59.19v-7.08c0-2.43.02-4.88.02-7.31v-26.97c0-.06,0-.12-.02-.18v-5.26h-2.4v5.26h-13.57c-4.1,0-7.45,3.31-7.45,7.45v29.82c0,4.1,3.35,7.45,7.45,7.45h13.57v5.26h2.4s0-5.26.02-5.26c0,0,0-3.17-.02-3.17Zm-14.48-32.51c1.55-1.46,2.37-3.01,2.78-3.96.41.95,1.23,2.5,2.78,3.96,1.79,1.68,3.66,2.41,4.69,2.73-1.03.32-2.91,1.05-4.69,2.73-1.55,1.46-2.37,3.01-2.78,3.96-.41-.95-1.23-2.5-2.78-3.96-1.79-1.68-3.66-2.41-4.69-2.73,1.03-.32,2.91-1.05,4.69-2.73Zm8.4,32.51h-9.6c-2.37,0-4.3-1.92-4.3-4.3v-2.31c0-.36.14-.7.39-.95l11.11-11.1,6.08,5.71v12.95h-3.68Z"
      />
      <path
        className="fill-white stroke-0"
        d="m54.91,17.64h-11.43v28.45l16.27-15.78v24.57c0,2.38-1.93,4.32-4.32,4.32h-11.96v3.17h11.43c4.13,0,7.45-3.35,7.45-7.45v-29.82c0-4.13-3.31-7.45-7.45-7.45Z"
      />
    </svg>
  );
}

export function AiQrCodeGeneratorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m62.36,17.63h-14.89v14.88h14.89v-14.88Zm-4.98,9.9c-.08.01-.17.03-.26.03-1.45.01-2.89.02-4.34.03-.21,0-.38,0-.38-.3.02-1.47.02-2.95.03-4.42,0-.08,0-.15.02-.27h4.93v4.93Z"
      />
      <path
        className="fill-white stroke-0"
        d="m17.64,47.47v14.9h14.89c0-.49,0-.96,0-1.43,0-4.35,0-8.7.03-13.05,0-.37-.11-.46-.47-.45-4.69.01-9.38,0-14.08.01-.12,0-.23,0-.37.02Zm4.97,4.97h4.96v4.94h-4.96v-4.94Z"
      />
      <path
        className="fill-white stroke-0"
        d="m32.52,17.64h-14.88v14.88h14.88v-14.88Zm-4.99,9.9h-4.94v-4.93h4.94v4.93Z"
      />
      <polygon
        className="fill-white stroke-0"
        points="52.41 57.39 52.41 57.4 52.42 57.4 52.42 57.39 52.41 57.39"
      />
      <path
        className="fill-white stroke-0"
        d="m52.41,47.43h-4.98v5h-9.89v9.93h4.96v-4.96c1.86,0,3.66,0,5.46,0,1.48,0,2.97,0,4.45,0,0-1.69,0-3.39,0-5.08,0-1.63,0-3.25,0-4.88Z"
      />
      <path className="fill-white stroke-0" d="m32.57,42.5h4.94v4.93h4.94v-9.88h-9.89v4.95Z" />
      <path
        className="fill-white stroke-0"
        d="m62.36,57.39v-9.94c-.19,0-.34,0-.5,0-1.48,0-2.97,0-4.45-.01,0,3.32,0,6.64,0,9.96h4.95Z"
      />
      <path className="fill-white stroke-0" d="m42.46,22.61h-4.93v9.91h4.93v-9.91Z" />
      <polygon
        className="fill-white stroke-0"
        points="52.42 47.42 52.41 47.42 52.41 47.43 52.42 47.43 52.42 47.42"
      />
      <polygon
        className="fill-white stroke-0"
        points="57.4 47.43 57.41 47.43 57.41 47.42 57.4 47.42 57.4 47.43 57.4 47.43"
      />
      <path
        className="fill-white stroke-0"
        d="m62.37,42.48v-4.93h-4.98v4.93c-1.34,0-2.63,0-3.92,0-.36,0-.72.05-1.09.08h0c.01.09.04.18.04.27,0,1.53,0,3.06,0,4.59.84.01,1.67.03,2.51.03.82,0,1.65-.02,2.47-.03v-4.95h4.97Z"
      />
      <path
        className="fill-white stroke-0"
        d="m52.42,62.35h4.97v-4.95c-1.66,0-3.31,0-4.97,0v4.95Z"
      />
      <path className="fill-white stroke-0" d="m57.4,57.4h.01s0-.01-.01-.01h0Z" />
      <path className="fill-white stroke-0" d="m22.58,37.54h-4.94v4.92h4.94v-4.92Z" />
      <path
        className="fill-white stroke-0"
        d="m52.43,37.79c0-.09-.02-.17-.04-.25h-4.92v4.94c1.14,0,2.25,0,3.37,0,.51,0,1.03.05,1.54.08.02-.1.05-.21.05-.31,0-1.49,0-2.97,0-4.46Z"
      />
    </svg>
  );
}

export function FaceSwapPhotoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m23.75,61.39h31.7c3.05,0,5.54-2.48,5.54-5.54v-31.7c0-3.06-2.48-5.54-5.54-5.54h-31.7c-3.05,0-5.54,2.48-5.54,5.54v31.7c0,3.06,2.48,5.54,5.54,5.54Zm-3.03-37.24c0-1.67,1.36-3.02,3.03-3.02h31.7c1.67,0,3.03,1.36,3.03,3.02v31.7c0,1.67-1.36,3.02-3.03,3.02h-31.7c-1.67,0-3.03-1.36-3.03-3.02v-31.7Z"
      />
      <path
        className="fill-white stroke-0"
        d="m41.26,36.64c1.34-.11,2.45-1.22,2.6-2.57.03-.24.06-.48.09-.76.13,0,.24,0,.33.03.22.08.44.16.64.28.51.31.92.74,1.33,1.17.45.47.71,1.04.77,1.68.05.49.05.98.06,1.47,0,.64,0,1.28,0,1.92,0,.55.35.89.9.92.07,0,.15,0,.22,0,.54-.03.89.25,1.1.72.24.53.21,1.06-.09,1.56-.26.43-.65.56-1.14.53-.53-.03-1.02.32-1,1.01.01.5-.02,1.01-.1,1.51-.18,1.12-.72,2.06-1.52,2.86-.55.55-1.17,1.01-1.83,1.43-.23.14-.31.31-.31.57.01.81,0,1.63,0,2.44,0,.12.02.24.03.35.37.09,6.88.08,7.21,0,0-.11.02-.23.02-.35,0-5.29,0-10.57,0-15.86,0-.94-.13-1.87-.37-2.77-.69-2.61-2.21-4.63-4.47-6.08-1.9-1.22-3.95-1.77-6.14-1.63v9.65s.04,0,.07,0c.53,0,1.06,0,1.59-.05Z"
      />
      <path
        className="fill-white stroke-0"
        d="m28.38,34.15c.19,1.05.25,2.1.1,3.16-.13.96-.11,1.92.26,2.84.06.14.02.33,0,.48-.39,1.75,0,3.37.83,4.91.07.13.16.27.28.34.5.31.64.83.82,1.34.7,1.99,1.74,3.75,3.42,5.07,1.16.91,2.43,1.36,3.71,1.41v3.31h.74V22.98h-.74v4.08s-.08-.08-.12-.14c-.36-.47-.69-.96-1.05-1.43-.16-.21-.32-.46-.74-.32-.09.5-.18,1.03-.29,1.7-.32-.23-.54-.37-.74-.54-.48-.39-.93-.81-1.42-1.18-.22-.16-.49-.24-.87-.42,0,.39-.03.63,0,.86.06.36.17.72.23,1.08.05.26.09.54.07.8-.02.32-.29.42-.52.21-.16-.14-.29-.34-.43-.5-.46-.55-.69-.52-1.02.1-.02.04-.05.09-.06.13-.48,1.25-1.14,2.39-1.99,3.43-.56.68-.76,1.48-.61,2.36.05.32.1.63.15.95Zm3,8.27c-.02-.21.01-.4-.05-.56-.31-.83-.33-1.69-.25-2.55.14-1.4.92-2.29,2.25-2.57.26-.06.55-.08.8-.02,1.22.33,2.44.38,3.66.34v15.73c-1.06-.04-2.1-.39-3.05-1.13-1.71-1.31-2.7-3.11-3.35-5.11-.11-.35-.21-.7-.33-1.09-.5,0-.74-.35-.91-.74-.48-1.07-.69-2.2-.65-3.42.73.2,1.01,1.07,1.89,1.13Z"
      />
      <path
        className="fill-white stroke-0"
        d="m28.45,53.49c1.1,1.05,2.49,1.72,3.95,1.98l.05-.24c-1.38-.36-2.65-1.11-3.58-2.15-.78-.87-1.29-1.94-1.51-3.06l.73.27-1.33-2.8-1.07,2.91.77-.37c.34,1.32,1.03,2.53,1.99,3.46Z"
      />
      <path
        className="fill-white stroke-0"
        d="m51.84,29.69l-.73-.27,1.33,2.8,1.07-2.91-.77.37c-.34-1.32-1.03-2.53-1.99-3.46-1.1-1.05-2.49-1.72-3.95-1.98l-.05.24c1.38.36,2.65,1.11,3.58,2.15.78.87,1.29,1.94,1.51,3.06Z"
      />
      <path
        className="fill-white stroke-0"
        d="m34.71,42.76c.44.11.89-.16,1-.6.11-.44-.16-.89-.6-1-.44-.11-.89.16-1,.6s.16.89.6,1Z"
      />
      <path
        className="fill-white stroke-0"
        d="m44.03,42.58c.13-.13.2-.3.31-.45.13-.17.29-.31.44-.46.23-.24.41-.52.59-.79-.03.04-.14.08-.18.1-.07.03-.14.06-.22.09-.54.19-1.11.04-1.66.16-.31.07-.56.26-.62.59-.05.28.06.58.28.77.06.05.13.1.21.13.28.12.63.08.85-.13Z"
      />
    </svg>
  );
}

export function ImageToImageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
      <path
        className="fill-white stroke-0"
        d="m29.05,47.44h-6.54c-1.62,0-2.93-1.31-2.93-2.93v-1.57c0-.24.1-.48.27-.65l7.57-7.56,2.02,1.9v-1.16c0-4.38,3.56-7.95,7.95-7.95h10.3v-3.31c0-2.81-2.26-5.07-5.07-5.07h-20.31c-2.79,0-5.07,2.26-5.07,5.07v20.31c0,2.79,2.28,5.07,5.07,5.07h7.13v-2.16h-.39Zm-2.68-22.41c1.88,0,3.4,1.52,3.4,3.4s-1.52,3.4-3.4,3.4-3.4-1.52-3.4-3.4,1.52-3.4,3.4-3.4Z"
      />
      <path
        className="fill-white stroke-0"
        d="m57.69,30.41h-20.31c-2.79,0-5.07,2.26-5.07,5.07v20.31c0,2.79,2.28,5.07,5.07,5.07h20.31c2.81,0,5.07-2.28,5.07-5.07v-20.31c0-2.81-2.26-5.07-5.07-5.07Zm-12.84,9.28c0,1.88-1.52,3.4-3.4,3.4s-3.4-1.52-3.4-3.4,1.52-3.4,3.4-3.4,3.4,1.52,3.4,3.4Zm16.14,16.07c0,1.62-1.32,2.94-2.94,2.94h-20.46c-1.62,0-2.93-1.31-2.93-2.93v-1.57c0-.24.1-.48.27-.65l7.57-7.56,5.75,5.4,12.75-12.37v16.73Z"
      />
    </svg>
  );
}
