import { SVGProps } from "react";

export function FaceSwapIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" {...props}>
      <path
        d="M11.2495 14.5016V15.9366L15.6245 12.8129L11.2495 9.99829V11.2772C9.48882 11.4399 7.85845 12.2747 6.6971 13.608C5.53576 14.9414 4.93272 16.6709 5.01324 18.4373C5.09377 20.2037 5.85168 21.8711 7.12953 23.0933C8.40739 24.3155 10.1069 24.9984 11.8752 25.0002C13.4502 25.0002 15.6245 24.446 16.8758 23.1248C18.4377 21.3208 20.3 16.7781 20.3 16.7781L14.7204 20.3947C14.3443 20.8192 13.8731 21.1486 13.3454 21.3561C12.8176 21.5637 12.2483 21.6434 11.6838 21.5888C11.1194 21.5342 10.5759 21.3469 10.0977 21.0421C9.61946 20.7373 9.22016 20.3236 8.93237 19.835C8.64459 19.3464 8.47648 18.7966 8.4418 18.2306C8.40711 17.6646 8.50683 17.0984 8.7328 16.5782C8.95877 16.0581 9.30458 15.5989 9.74199 15.2379C10.1794 14.877 10.696 14.6247 11.2495 14.5016Z"
        fill="white"
      />
      <path
        d="M23.7504 21.1224V19.6874L19.3754 22.8127L23.7504 25.6243V24.3468C25.5111 24.1841 27.1415 23.3494 28.3028 22.016C29.4641 20.6827 30.0672 18.9532 29.9867 17.1868C29.9061 15.4204 29.1482 13.7529 27.8704 12.5308C26.5925 11.3086 24.893 10.6257 23.1247 10.6239C21.5497 10.6239 19.3754 11.1781 18.1256 12.4993C16.5622 14.3033 14.7 18.846 14.7 18.846L20.2795 15.2293C20.6557 14.8058 21.1268 14.4773 21.6541 14.2705C22.1815 14.0637 22.7503 13.9845 23.3141 14.0393C23.8779 14.0942 24.4207 14.2816 24.8983 14.5862C25.3759 14.8909 25.7747 15.3041 26.0622 15.7922C26.3497 16.2803 26.5177 16.8295 26.5525 17.3949C26.5873 17.9603 26.488 18.5259 26.2626 19.0456C26.0372 19.5653 25.6921 20.0243 25.2555 20.3852C24.8189 20.7462 24.3032 20.9988 23.7504 21.1224Z"
        fill="white"
      />
    </svg>
  );
}
