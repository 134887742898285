import { ProjectType } from "@prisma/client";

export const gradientBackgroundWithoutHover =
  "bg-gradient-to-r from-teal-500 via-indigo-600 to-purple-600";

export const gradientBackgroundClasses = `${gradientBackgroundWithoutHover} hover:from-teal-400 hover:via-indigo-500 hover:to-purple-700`;

export const discordInviteUrl = "https://discord.gg/JX5rgsZaJp";

type ProjectTypeAttribute = { [key in ProjectType]: number };

export const minNumberOfFrames = 10;
export const maxNumberOfFrames: ProjectTypeAttribute = {
  ANIMATION: 10000,
  FACE_SWAP: 20000,
  IMAGE_TO_VIDEO: 2000,
  LIP_SYNC: 20000,
  TEXT_TO_VIDEO: 2000,
  VIDEO_TO_VIDEO: 1800,
};

export const costMultiplierByType: ProjectTypeAttribute = {
  ANIMATION: 1,
  FACE_SWAP: 1,
  IMAGE_TO_VIDEO: 2,
  LIP_SYNC: 1,
  TEXT_TO_VIDEO: 2,
  VIDEO_TO_VIDEO: 2,
};

// In order to make the value of an environment variable accessible in the browser, Next.js can "inline" a value, at build time,
// into the js bundle that is delivered to the client, replacing all references to process.env.[variable] with a hard-coded value.
// To tell it to do this, you just have to prefix the variable with NEXT_PUBLIC_.
export const webAssetsCdnBaseUrl =
  process.env["NEXT_PUBLIC_WEB_ASSETS_CDN"] || process.env.NEXTAUTH_URL || "";
export const exploreVideoCdnBaseUrl = process.env.NEXT_PUBLIC_EXPLORE_VIDEO_CDN_URL ?? "";
export const userVideoCdnBaseUrl = process.env.NEXT_PUBLIC_USER_VIDEO_CDN_URL ?? "";

export const oneGbInBytes = 1024 * 1024 * 1024;

export const discordWebhookUrls = {
  alerts:
    "https://discord.com/api/webhooks/1145930611048067082/GNc1D9-yRVzSERoJFpDmZZotzl6KYm1WBJTUEEtL-GuAMbdTqLwd5JJlLnlnOXvvQD0Y",
  p0: "https://discord.com/api/webhooks/1131477588448116756/bcnsfqb4Br4w7uJXOPuTEaAyjZJbh3M0rA6khy_7gcGVSB1-Y2Abyh035fH6maRN_k67",
  p1: "https://discord.com/api/webhooks/1161461709718962246/iSgpvcXwGu_r9__w6AXiDjhqRmmSnVsp1ij5vaf2QqkZMoE2CKcpMOz4S9qoanfGinuQ",
  p2: "https://discord.com/api/webhooks/1199127079241908234/dhcMJqtcT8L7io42FuJYcyZozVzj0_5awfm4KmTc7Fd0n7NQOmw8yEkTB8OqEe2AKJ_6",
  stripe:
    "https://discord.com/api/webhooks/1296311158000844810/04PA5HTLVVfvrprSmqyKaDdF0jQiLz4W9lbEizxq_o98hIIKq61F3fzUdxsXjJ7Ktjed",
};

export const signupFrames = 400;
