import { ImageType, ProjectType } from "@prisma/client";
import { ElementType } from "react";
import {
  AiHeadshotGeneratorIcon,
  AiImageGeneratorIcon,
  AiImageUpscalerIcon,
  AiQrCodeGeneratorIcon,
  AnimationIcon,
  FaceSwapVideoIcon,
  ImageToVideoIcon,
  LipSyncIcon,
  TextToVideoIcon,
  VideoToVideoIcon,
} from "./project-icons";
import { FaceSwapIcon } from "@/app/(signed-in)/create/face-swap-video/face-swap-icon";
import { UpsellModal } from "@/shared/upsell-modal";

interface MetadataBase {
  name: string;
  description: string;
  link: string;
  src: string;
  Icon: ElementType;
  needSubscription: boolean;
  needFramePack: boolean;
  upsellModalName?: UpsellModal;
  status: "live" | "new" | "comingSoon" | "hidden";
}

interface MetadataFree extends MetadataBase {
  needSubscription: false;
  needFramePack: false;
  upsellModalName: undefined;
}

interface MetadataPaid extends MetadataBase {
  needSubscription: true | false;
  needFramePack: true;
  upsellModalName: UpsellModal;
}

export const projectTypeMetadata: {
  [type in ProjectType]: MetadataFree | MetadataPaid;
} = {
  ANIMATION: {
    name: "Animation",
    description: "Create stop-motion animations",
    link: "/create/animation",
    src: "animation",
    Icon: AnimationIcon,
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
    status: "live",
  },
  VIDEO_TO_VIDEO: {
    name: "Video-to-Video",
    description: "Apply new styles to your videos",
    link: "/create/video-to-video",
    src: "video-to-video",
    Icon: VideoToVideoIcon,
    needSubscription: true,
    needFramePack: true,
    upsellModalName: "unlockVideoToVideo",
    status: "live",
  },
  FACE_SWAP: {
    name: "Face Swap Video",
    description: "Easily swap faces in any video",
    link: "/create/face-swap-video",
    src: "faceswap",
    Icon: FaceSwapVideoIcon,
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
    status: "live",
  },
  TEXT_TO_VIDEO: {
    name: "Text-to-Video",
    description: "Create realistic videos from text",
    link: "/create/text-to-video",
    src: "text-to-video",
    Icon: TextToVideoIcon,
    needSubscription: true,
    needFramePack: true,
    upsellModalName: "unlockTextToVideo",
    status: "live",
  },
  IMAGE_TO_VIDEO: {
    name: "Image-to-Video",
    description: "Turn images into videos",
    link: "/create/image-to-video",
    src: "image-to-video",
    Icon: ImageToVideoIcon,
    needSubscription: true,
    needFramePack: true,
    upsellModalName: "unlockImageToVideo",
    status: "live",
  },
  LIP_SYNC: {
    name: "Lip Sync",
    description: "Sync your video with any audio",
    link: "/create/lip-sync",
    src: "lip-sync",
    Icon: LipSyncIcon,
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
    status: "live",
  },
};

export const freeUserTypeOrder: ProjectType[] = [
  "FACE_SWAP",
  "LIP_SYNC",
  "ANIMATION",
  "TEXT_TO_VIDEO",
  "IMAGE_TO_VIDEO",
  "VIDEO_TO_VIDEO",
];

export const paidUserTypeOrder: ProjectType[] = [
  "TEXT_TO_VIDEO",
  "IMAGE_TO_VIDEO",
  "VIDEO_TO_VIDEO",
  "FACE_SWAP",
  "LIP_SYNC",
  "ANIMATION",
];

export const imageTypeOrder: ImageType[] = [
  "AI_IMAGE",
  "QR_CODE",
  "FACE_SWAP",
  "IMAGE_UPSCALER",
  "AI_HEADSHOT",
];

export const dailyFramesReward = 100;

export const imageTypeMetadata: {
  [type in ImageType]: MetadataFree | MetadataPaid;
} = {
  AI_HEADSHOT: {
    name: "AI Headshot Generator",
    description: "Generate professional headshots",
    link: "/create/ai-headshot-generator",
    src: "ai-headshot-generator",
    Icon: AiHeadshotGeneratorIcon,
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
    status: "live",
  },
  AI_IMAGE: {
    name: "AI Image Generator",
    description: "Generate images from text",
    link: "/create/ai-image-generator",
    src: "ai-image-generator",
    Icon: AiImageGeneratorIcon,
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
    status: "live",
  },
  BACKGROUND_REMOVER: {
    name: "Image Background Remover",
    description: "Remove background instantly",
    link: "/create/image-background-remover",
    src: "image-background-remover",
    Icon: AiImageUpscalerIcon,
    status: "live",
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
  },
  FACE_SWAP: {
    name: "Face Swap Photo",
    description: "Easily swap faces in any photo",
    link: "/create/face-swap-photo",
    src: "face-swap-photo",
    Icon: FaceSwapIcon,
    status: "live",
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
  },
  IMAGE_UPSCALER: {
    name: "AI Image Upscaler",
    description: "Enhance details in your image",
    link: "/create/ai-image-upscaler",
    src: "ai-image-upscaler",
    Icon: AiImageUpscalerIcon,
    status: "live",
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
  },
  QR_CODE: {
    name: "AI QR Code Generator",
    description: "Generate unique QR Codes",
    link: "/create/ai-qr-code-generator",
    src: "ai-qr-code-generator",
    Icon: AiQrCodeGeneratorIcon,
    status: "live",
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
  },
  PHOTO_EDITOR: {
    name: "AI Photo Editor",
    description: "Edit your photos with AI",
    link: "/create/ai-photo-editor",
    src: "ai-photo-editor",
    Icon: AiImageUpscalerIcon, // TODO: update
    status: "hidden",
    needSubscription: false,
    needFramePack: false,
    upsellModalName: undefined,
  },
};
